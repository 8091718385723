<template src="./confirmation.html"></template>
<style scoped src="./confirmation.css"></style>

<script> 
import Loading from 'vue-loading-overlay'
import TransactionService from '@/services/TransactionService.vue'
import modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils.vue'
import moment from 'moment'

export default {
    components:{
        Loading, modal
    },
    data () {
        return {
            sendmoney: {},
            loaderVisible : false,
            notes : null,
            resdata : {
                notes : []
            },
            currentPage : 1,
            perPage : 8,
            senderEmail : null,
            transactionId: null,
            loadSummary : false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            fields: [
                {key: 'CreateDateUtc', label: 'Created Date'},
                {key: 'CreatedBy', label: 'Created by'},
                {key: 'Notes', label: 'Note' }
            ]
        }
    },
    methods : {
        async handleAddNotesEvent() {
            this.loaderVisible = true
            await TransactionService.methods.AddTransactionNotes(this.notes,this.sendmoney.ReferenceNumber._text)
            .then(() => {
                this.showAlert(1, 'Notes Added Successfully')
                this.notes = '' 
                this.getTransactionDetails(this.senderEmail,this.transactionId)   
            })
            .catch( err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
            this.loaderVisible = false
        },

        //to get Transaction notes it is called separately while adding 
        getTransactionDetails(senderEmail,transactionId) {  
            this.senderEmail = senderEmail
            this.transactionId = transactionId
            this.loaderVisible = true
            TransactionService.methods.GetTransactionDeatils({
                CustomerEmail : senderEmail,
                TransactionId : transactionId
            }).then(res => {
                this.resdata.notes = Object.keys(res.csTransactionDetailsResponse.TransactionNotes).length > 0 ? Array.isArray(res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote) ? (res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote).map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Notes: s.Note._text })) : [{
                    CreateDateUtc : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreateDateUtc._text,
                    CreatedBy  : res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.CreatedBy._text,
                    Notes  :  res.csTransactionDetailsResponse.TransactionNotes.csTransactionNote.Note._text
                }] : []
                this.resdata.notes.forEach(note => {
                    note.CreateDateUtc = utils.methods.UTCtoLocal(moment(note.CreateDateUtc).format('MM/DD/YYYY HH:mm:ss'))
                })
            })
            .catch(er =>{
                this.alertObject = er
                this.$bvModal.show(this.$refs.childModal.id)
            })
            this.loaderVisible = false
        },
       showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        },
        
    },
    computed : {
        rows () {
            return this.resdata.notes?.length
        }
    },
    async created () {
        this.loaderVisible = true
        if(this.$route.params.resSendMoney !== undefined) {
            this.loadSummary = true
            this.sendmoney = this.$route.params.resSendMoney
            this.sendmoney.DateTime._text = utils.methods.UTCtoLocal(moment(moment(this.sendmoney.DateTime._text, 'MM/DD/YYYY HH:mm:ss')._d).format( 'MM-DD-YYYY HH:mm:ss'))
            this.sendmoney.parentReferenceNumber = this.$route.params.parentReferenceNumber._text
           await TransactionService.methods.GetTransactionSummary({
                ReferenceNumber : this.$route.params.parentReferenceNumber._text
            }).then(res => {
                if(Object.keys(res.csTransactionSummaryResponse.TransactionSummary).length > 0){
                    if(Array.isArray(res.csTransactionSummaryResponse.TransactionSummary)){
                        this.getTransactionDetails(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem[0].SenderEmail._text,res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem[0].TransactionId._text)
                    }else{
                        this.getTransactionDetails(res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem.SenderEmail._text,res.csTransactionSummaryResponse.TransactionSummary.csTransactionSummaryItem.TransactionId._text)
                    }
                }
            }).catch(er => {
                this.alertObject = er
                this.$bvModal.show(this.$refs.childModal.id)
            })     
        }else{
            this.loadSummary = false
            this.$router.push({
                name: 'Dashboard'
            })
        }
        this.loaderVisible = false
    }
}
</script>